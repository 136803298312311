import ReactDOM from "react-dom/client";
import App from "./App";
import { store } from "./store";
import { Provider } from "react-redux";
import "normalize.css";
import "./styles/index.scss";
import { ConfigProvider } from "antd";
import { BrowserRouter } from "react-router-dom";
import { MessageContextProvider } from "./context/messageContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "HK Grotesk",
        },
        components: {
          Button: {},
        },
      }}
    >
      <BrowserRouter>
        <MessageContextProvider>
          <App />
        </MessageContextProvider>
      </BrowserRouter>
    </ConfigProvider>
  </Provider>
);
