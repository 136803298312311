import axios from "axios";
import {
  ISigninData,
  ISignupData,
  IUpdateAdmin,
  IUserInvitationData,
  IUserExclusionData,
  Status,
  IUpdateUser,
} from "../types";

let baseURL = "";

if (process.env.NODE_ENV === "development") {
  baseURL = "http://localhost:8080/api/admin";
} else {
  baseURL = "https://endpoints.hypelist.com/api/admin";
}

export const signUp = (data: ISignupData) => {
  return axios.post(`${baseURL}/signup`, data);
};

export const signIn = (data: ISigninData) => {
  return axios.post(`${baseURL}/signin`, data);
};

export const getMetrics = () => {
  return axios.get(`${baseURL}/metrics`);
};

export const getUsers = (
  offset: number,
  sortOrderColumns: Record<string, string | null>
) => {
  return axios.post(`${baseURL}/userdata`, {
    offset,
    sortOrder: sortOrderColumns,
  });
};

export const getUserById = (id: string) => {
  return axios.get(`${baseURL}/userdata/${id}`);
};

export const getReferredUsersByUser = (id: string) => {
  return axios.get(`${baseURL}/referred-users/${id}`);
};

export const updateUser = (id: string, data: IUpdateUser) => {
  return axios.patch(`${baseURL}/userdata/${id}`, data);
};

export const getHypelist = (userId: string, hypelistUrl: string) => {
  return axios.get(`${hypelistUrl}`, {
    headers: {
      uid: userId,
    },
  });
};

export const getReportedHypelist = (hypelistUrl: string) => {
  return axios.get(`${hypelistUrl}`);
};

export const getAllAvailableHypelists = (offset: number) => {
  return axios.post(`${baseURL}/hypelistsdata`, {
    offset,
  });
};

export const searchAllHypelists = (value: string) => {
  return axios.get(`${baseURL}/search/allhypelist/${value}?limit=15`);
};

export const searchUsers = (value: string) => {
  return axios.get(`${baseURL}/search/user/${value}`);
};

export const getAdmins = (offset: number) => {
  return axios.post(`${baseURL}/admindata`, {
    offset,
  });
};

export const getAdminById = (id: string) => {
  return axios.get(`${baseURL}/admindata/${id}`);
};

export const updateAdmin = (id: string, data: IUpdateAdmin) => {
  return axios.patch(`${baseURL}/admindata/${id}`, data);
};

export const deleteAdmin = (id: string) => {
  return axios.delete(`${baseURL}/${id}`);
};

export const getReportedList = (offset: number = 0) => {
  return axios.post(`${baseURL}/reportedHypelists`, {
    offset,
  });
};

export const searchReportedList = (value: string) => {
  return axios.get(`${baseURL}/search/hypelist/${value}`);
};

// Deprecated
export const sendNotification = (data: { title: string; body: string }) => {
  return axios.post(`${baseURL}/notification`, data);
};

// Deprecated
export const getNotifications = () => {
  return axios.get(`${baseURL}/notification`);
};

export const changeHypelistStatus = (status: Status, hypelistId: string) => {
  const data = {
    status,
  };

  return axios.patch(`${baseURL}/hypelist/${hypelistId}`, data);
};

export const getAllInvitations = (offset: number = 0) => {
  return axios.post(`${baseURL}/invitations`, {
    offset,
  });
};

export const searchInvitations = (value: string, offset: number = 0) => {
  return axios.post(`${baseURL}/invitations/search`, {
    term: value,
    offset: offset,
  });
};

export const addInvitation = (data: IUserInvitationData) => {
  return axios.post(`${baseURL}/invitations/add`, data);
};

export const getAllExclusions = (offset: number = 0) => {
  return axios.post(`${baseURL}/exclusions`, {
    offset,
  });
};

export const searchExclusions = (value: string, offset: number = 0) => {
  return axios.post(`${baseURL}/exclusions/search`, {
    term: value,
    offset: offset,
  });
};

export const addExclusions = (data: IUserExclusionData) => {
  return axios.post(`${baseURL}/exclusions/add`, data);
};

export const getAllCuratedLists = () => {
  return axios.get(`${baseURL}/curated?includeInvisible=true`);
};

export const getCuratedList = (id: string) => {
  return axios.get(`${baseURL}/curated/${id}`);
};

export const getAllHighlights = () => {
  return axios.get(`${baseURL}/curated/highlights`);
};

export const deleteHighlight = (id: string) => {
  return axios.delete(`${baseURL}/curated/highlight/${id}`);
};

export const createHighlight = (payload: any) => {
  return axios.post(`${baseURL}/curated/highlight`, payload);
};

export const updateHighlight = (id: string, payload: any) => {
  return axios.patch(`${baseURL}/curated/highlight/${id}`, payload);
};

export const moveCuratedList = (id: string, order: number) => {
  return axios.post(`${baseURL}/curated/ordering`, {
    id,
    order,
  });
};

export const deleteCuratedList = (id: string) => {
  return axios.delete(`${baseURL}/curated/${id}`);
};

export const createCuratedList = (title: string, uids: string[]) => {
  return axios.post(`${baseURL}/curated`, {
    title,
    hypelistIds: uids,
  });
};

export const toggleListVisibility = (id: string, visible: boolean) => {
  return axios.patch(`${baseURL}/curated/${id}`, {
    visible,
  });
};

export const getFollowPeople = () => {
  return axios.get(`${baseURL}/follow`);
};

export const addFollowPeople = (userUID: string) => {
  return axios.post(`${baseURL}/follow`, {
    userUID: userUID,
  });
};

export const removeFollowPeople = (userID: string) => {
  return axios.delete(`${baseURL}/follow/${userID}`);
};
export const saveCuratedListHypelistOrder = (
  hypelistId: string,
  curatedListId: string,
  order: number
) => {
  return axios.post(`${baseURL}/curated/hypelist-ordering`, {
    hypelistId,
    curatedListId,
    order,
  });
};

export const deleteHypelistFromCuratedList = (
  hypelistId: string,
  curatedListId: string
) => {
  return axios.patch(`${baseURL}/curated/${curatedListId}/remove`, {
    hypelistIds: [hypelistId],
  });
};

export const editCuratedList = (curatedListId: string, newFields: any) => {
  return axios.patch(`${baseURL}/curated/${curatedListId}`, {
    ...newFields,
  });
};

export const addHypelistToCuratedList = (
  hypelistId: string,
  curatedListId: string
) => {
  return axios.patch(`${baseURL}/curated/${curatedListId}/add`, {
    hypelistIds: [hypelistId],
  });
};

export const reorderCuratedListHypelists = (
  hypelistOrderingIds: string[],
  curatedListId: string
) => {
  return axios.post(`${baseURL}/curated/hypelist-id-ordering`, {
    id: curatedListId,
    orderingIds: hypelistOrderingIds,
  });
};

export const reorderHighlights = (highlightsOrderingIds: string[]) => {
  return axios.post(`${baseURL}/curated/reorder-highlights`, {
    ids: highlightsOrderingIds,
  });
};

export const getAllTemplates = () => {
  return axios.get(`${baseURL}/curated/templates`);
};

export const deleteTemplate = (id: string) => {
  return axios.delete(`${baseURL}/curated/template/${id}`);
};

export const createTemplate = (payload: any) => {
  return axios.post(`${baseURL}/curated/template`, payload);
};

export const reorderTemplates = (templateOrderingIds: string[]) => {
  return axios.post(`${baseURL}/curated/reorder-templates`, {
    ids: templateOrderingIds,
  });
};

export const uploadImage = (image: File, folder: string) => {
  const formData = new FormData();
  formData.append("file", image);
  return axios.post(`${baseURL}/upload-image?folder=${folder}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data", // Important for sending files
    },
  });
};

export const uploadVideo = (video: File, folder: string) => {
  const formData = new FormData();
  formData.append("file", video);
  return axios.post(`${baseURL}/upload-video?folder=${folder}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data", // Important for sending files
    },
  });
};

export const getSettings = () => {
  return axios.get(`${baseURL}/settings`);
};

export const updateSettings = (settings: Record<string, any>) => {
  return axios.patch(`${baseURL}/settings`, settings);
};
