import { Button, Card, Form, Input } from "antd";
import { ChangeEvent, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LogoIcon } from "../../components/icons";
import { MessageContext } from "../../context/messageContext";
import { RootState } from "../../store";
import {
  setAuthData,
  setAuthenticated,
  setAuthResponseData,
} from "../../store/actions/authActions";
import { setLoader } from "../../store/actions/mainActions";
import { signIn } from "../../utils/api";
import parseErrors from "../../utils/parseErrors";

const Signin = () => {
  const dispatch = useDispatch();
  const authData = useSelector((state: RootState) => state.authStore.authData);
  const messageContext = useContext(MessageContext);

  const onFinish = async () => {
    dispatch(setLoader(true));

    try {
      const res = await signIn(authData);

      dispatch(setAuthenticated(true));
      dispatch(setAuthResponseData(res.data.data));
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: "error",
          content: errors[i],
        });
      }
    }

    dispatch(setLoader(false));
  };

  const onChange = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setAuthData({
        name,
        value: event.target.value,
      })
    );
  };

  return (
    <div className="signin">
      <Card>
        <Form
          name="signin"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <div className="text-center">
            <LogoIcon />
            <h2>Sign in</h2>
          </div>

          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input value={authData.username} onChange={onChange("username")} />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              value={authData.password}
              onChange={onChange("password")}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Sign in
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Signin;
